import { Button, Headline, Input } from '@hallosonne/components';
import { Field, FieldProps, Form, Formik } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';
import { de } from 'yup-locales';

import { loginWithPassword } from '../services/supabase/utils/auth';
import { useStore } from '../store/store';

yup.setLocale(de);
const loginSchema = yup
  .object<{ email: yup.AnySchema; password: yup.AnySchema }>()
  .shape({
    email: yup
      .string()
      .email()
      .required('E-Mail ist ein Pflichtfeld')
      .label('E-Mail'),
    password: yup
      .string()
      .required('Passwort ist ein Pflichtfeld')
      .label('Passwort'),
  });

const LoginPage = () => {
  const router = useRouter();

  const [isLoginRequested, setIsLoginRequested] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const { user } = useStore();

  if (user) {
    router.replace('/geplante-installationen');
    return;
  }

  return (
    <>
      <div className="my-7">
        <Headline element="h2" align="center" weight="bold">
          HalloSonne Partnerportal
        </Headline>
      </div>
      <div className="mb-6 text-center">
        <Headline element="h4" align="center" weight="bold">
          Bitte melde dich mit deiner E-Mail Adresse und Passwort an
        </Headline>
      </div>

      <Formik
        validationSchema={loginSchema}
        initialValues={{
          email: '',
          password: '',
        }}
        validateOnChange={false}
        onSubmit={form => {
          if (!isLoginRequested) {
            setIsLoginRequested(true);
            setLoginError(undefined);
            loginWithPassword(form.email, form.password)
              .then(result => {
                if (result.success) {
                  router.reload();
                } else {
                  setIsLoginRequested(false);
                  setLoginError(result.error);
                }
              })
              .catch(() => setIsLoginRequested(false));
          }
        }}
      >
        {() => (
          <Form
            id="form-login"
            className="flex flex-col my-6 max-w-sm mx-auto justify-center"
          >
            <div className="flex flex-col gap-4">
              <Field name="email">
                {({ field, form }: FieldProps<string, { email: string }>) => (
                  <Input
                    label="E-Mail"
                    value={field.value}
                    placeholder="E-Mail Addresse eingeben"
                    onChange={newValue => {
                      form.setFieldValue(field.name, newValue.trim());
                    }}
                    errorText={form.errors.email}
                    required
                  />
                )}
              </Field>
              <Field name="password">
                {({
                  field,
                  form,
                }: FieldProps<string, { password: string }>) => (
                  <Input
                    type="password"
                    label="Passwort"
                    placeholder="Passwort eingeben"
                    value={field.value}
                    onChange={newValue => {
                      form.setFieldValue(field.name, newValue);
                    }}
                    errorText={form.errors.password}
                    required
                  />
                )}
              </Field>
            </div>
            <Link
              href="/password/recovery"
              className="underline text-p-xSmall mt-2"
            >
              Neues Passwort anfordern
            </Link>

            {loginError && (
              <span className="block mt-2 text-error-base">
                <strong>{loginError}</strong>
              </span>
            )}

            <div className="pt-5 w-full">
              <Button
                className="bg-primary-base w-full"
                formName="form-login"
                disabled={isLoginRequested}
                loading={isLoginRequested}
              >
                Login
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginPage;
